import React, { useState, useEffect } from 'react';
import './navbar.css';
import logo from "../../assets/reboriselogoplusname.png";
import contactImg from "../../assets/contact.png";
import menu from '../../assets/menuiconblack.png';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [activePage, setActivePage] = useState('');

  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/' || path === '/home') {
      setActivePage('home');
    } else if (path === '/services') {
      setActivePage('services');
    } else if (path === '/about') {
      setActivePage('about');
    } else if (path === '/insights') {
      setActivePage('insights');
    } 
    else if(path === '/contactus'){
      setActivePage('contactus');
    }
  }, []);

  return (
    <nav className="navbar">
      <img onClick={() => {
        window.location = "/";
        setActivePage('home');
      }} src={logo} alt="Logo" className="logo" />
      <div className="desktopMenu">
        <div onClick={() => {
          window.location = "/";
          setActivePage('home');
        }} className={`desktopMenuListItem ${activePage === 'home' ? 'active' : ''}`}>Home</div>
        <div onClick={() => {
          window.location = "/about";
          setActivePage('about');
        }} className={`desktopMenuListItem ${activePage === 'about' ? 'active' : ''}`}>About Us</div>
        <div onClick={() => {
          window.location = "/services";
          setActivePage('services');
        }} className={`desktopMenuListItem ${activePage === 'services' ? 'active' : ''}`}>Services</div>
        <div onClick={() => {
          window.location = "https://blogs.reborise.com/";
          setActivePage('insights');
        }} className={`desktopMenuListItem ${activePage === 'insights' ? 'active' : ''}`}>Blogs</div>
      </div>
      <button className="desktopMenuBtn" onClick={() => {
        window.location="/contactus"
      }}>
        <img src={contactImg} alt="" className="desktopMenuImg" />Contact Us
      </button>

      <img src={menu} alt="Menu" className="mobMenu" onClick={() => {
        setShowMenu(!showMenu);
      }} />
      <div className="navMenu" style={{ display: showMenu ? 'flex' : 'none' }}>
        <div onClick={() => {
          window.location = "/";
          setShowMenu(false);
          setActivePage('home');
        }} className={`listItem ${activePage === 'home' ? 'active' : ''}`}>Home</div>
        <div onClick={() => {
          window.location = "/about";
          setShowMenu(false);
          setActivePage('about');
        }} className={`listItem ${activePage === 'about' ? 'active' : ''}`}>About Us</div>
        <div onClick={() => {
          window.location = "/services";
          setShowMenu(false);
          setActivePage('services');
        }} className={`listItem ${activePage === 'services' ? 'active' : ''}`}>Services</div>
        <div onClick={() => {
          window.location = "https://blogs.reborise.com/";
          setShowMenu(false);
          setActivePage('insights');
        }} className={`listItem ${activePage === 'insights' ? 'active' : ''}`}>Blogs</div>
        <div onClick={() => {
          window.location="/contactus"
          setShowMenu(false);
          setActivePage('contactus');
        }} className={`listItem ${activePage === 'contactus' ? 'active' : ''}`}>Contact Us</div>
      </div>
    </nav>
  );
};

export default Navbar;
